import createCachedSelector from 're-reselect';
import { isNil, path, prop, sort } from 'ramda';

const sortableTablePath = ['sortableTable'];

// sortBySelector :: (State, Props) -> String
export const sortBySelector = (state, props) =>
  path([...sortableTablePath, 'sortBy', props.id], state);

// isSortAscendingSelector :: (State, Props) -> Boolean
export const isSortAscendingSelector = (state, props) =>
  path([...sortableTablePath, 'isSortAscending', props.id], state);

// sortedDataSelector :: (State, Props) -> Array
export const sortedDataSelector = createCachedSelector(
  sortBySelector,
  isSortAscendingSelector,
  (_, props) => props.data,
  (_, props) => props.columns,
  (sortBy, isSortAscending, data, columns) => {
    const sortByColumn = columns.find(column => sortBy === column.title);
    const shouldSort = sortByColumn && !sortByColumn.noSort;

    const getProp = sortByColumn
      ? typeof sortByColumn.property === 'function'
        ? sortByColumn.property
        : item => prop(sortByColumn.property, item)
      : null;

    return shouldSort && !isNil(getProp) && !isNil(isSortAscending)
      ? sort((a, b) => {
          const propA = getProp(a);
          const propB = getProp(b);

          if (isNil(propA)) {
            return 1;
          } else if (isNil(propB)) {
            return -1;
          } else if (propA === propB) {
            return 0;
          } else if (isSortAscending) {
            return propA < propB ? -1 : 1;
          } else {
            return propA < propB ? 1 : -1;
          }
        }, data)
      : data;
  }
)((_, props) => props.id);
