import { createAction } from 'redux-actions';
import * as types from './types';
import * as selectors from './selectors';

const setSortBy = createAction(types.SET_SORT_BY, (id, value) => ({
  id,
  value,
}));

const toggleIsSortAscending = createAction(types.TOGGLE_IS_SORT_ASCENDING);

const resetIsSortAscending = createAction(types.RESET_IS_SORT_ASCENDING);

export const setSort = (id, sortBy) => (dispatch, getState) => {
  const state = getState();
  const currentSortBy = selectors.sortBySelector(state, { id });

  if (sortBy === currentSortBy) {
    dispatch(toggleIsSortAscending(id));
  } else {
    dispatch(setSortBy(id, sortBy));
    dispatch(resetIsSortAscending(id));
  }
};
