import React from 'react';
import { IdentityContextProvider } from 'react-netlify-identity';

import { NETLIFY_DOMAIN } from '/src/constants';

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => (
  <IdentityContextProvider url={NETLIFY_DOMAIN}>
    {element}
  </IdentityContextProvider>
);
