import { handleActions } from 'redux-actions';
import * as types from './types';

export const initialState = {};
export const initialFormState = {
  step: 0,
  complete: -1,
};

export default handleActions(
  {
    [types.INIT]: (state, { payload: { formId } }) => ({
      ...state,
      [formId]: initialFormState,
    }),
    [types.SET_FORM]: (state, { payload: { formId, form } }) => ({
      ...state,
      [formId]: form,
    }),
    [types.SET_STEP]: (state, { payload: { formId, step } }) => ({
      ...state,
      [formId]: {
        ...state[formId],
        step,
      },
    }),
    [types.SET_COMPLETE]: (state, { payload: { formId, complete } }) => ({
      ...state,
      [formId]: {
        ...state[formId],
        complete,
      },
    }),
    [types.RESET_STEPS]: (state, { payload: formId }) => ({
      ...state,
      [formId]: initialFormState,
    }),
  },
  initialState
);
