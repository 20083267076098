import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import { propEq } from 'ramda';
import * as types from './types';

const initialState = {
  items: [],
  shipping: {
    firstName: '',
    lastName: '',
    email: '',
    state: '',
    city: '',
    line1: '',
    line2: '',
    zip: '',
  },
};

const itemsReducer = handleActions(
  {
    [types.ADD_ITEM]: (state, { payload }) => {
      const exists = state.some(propEq('id', payload.id));

      return exists
        ? payload.quantity
          ? state.map(item => ({
              ...item,
              quantity:
                item.id === payload.id
                  ? Number(item.quantity) + Number(payload.quantity)
                  : Number(item.quantity),
            }))
          : state
        : [...state, payload];
    },
    [types.SET_ITEM]: (state, { payload }) =>
      state.map(item =>
        item.id === payload.id ? { ...item, ...payload } : item
      ),
    [types.REMOVE_ITEM]: (state, { payload }) =>
      state.filter(item => item.id !== payload.id),
    [types.CLEAR_ITEMS]: () => initialState.items,
  },
  initialState.items
);

const shippingReducer = handleActions(
  {
    [types.SET_SHIPPING]: (state, { payload }) => ({ ...state, ...payload }),
  },
  initialState.shipping
);

export default combineReducers({
  items: itemsReducer,
  shipping: shippingReducer,
});
