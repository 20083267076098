import { applyMiddleware, compose, createStore } from 'redux';
// import { createBrowserHistory } from 'history';
import thunkMiddleware from 'redux-thunk';
// import { reactReduxFirebase } from 'react-redux-firebase';
// import { middleware as apiMiddleware } from 'redux-api-call';
// import firebase from '../services/firebase';
// import { enhancer as authEnhancer } from './auth';
// import { enhancer as connectionsEnhancer } from './connections';
import persistState from 'redux-localstorage';

import isBrowser from '/src/utils/isBrowser';
import reducer from './reducers';

export default (initialState = {}) => {
  // Create browser history
  // const history = createBrowserHistory();

  // Middleware configuration
  const middleware = [
    thunkMiddleware,
    // apiMiddleware,
  ];

  // react redux firebase
  // const rrfConfig = {
  //   userProfile: 'users',
  // };

  // Store enhancers
  const enhancers = [
    ...(isBrowser() ? [persistState(['cart'], { key: 'eoa_redux' })] : []),
    // authEnhancer(),
    // connectionsEnhancer(),
    // reactReduxFirebase(firebase, rrfConfig),
  ];

  const isDevelopment = process.env.NODE_ENV === 'development';
  let composeEnhancers = compose;

  if (
    isDevelopment &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ) {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  }

  // Store instantiation
  const store = createStore(
    reducer,
    initialState,
    composeEnhancers(applyMiddleware(...middleware), ...enhancers)
  );

  // store.history = history;

  return store;
};
