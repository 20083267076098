import { createSelector } from 'reselect';
import isNullOrUndefined from '../../utils/isNullOrUndefined';
import { initialFormState } from './reducer';

const getNumericalVal = (form, field) =>
	!!form && !isNullOrUndefined(form[field])
		? form[field]
		: initialFormState[field];

const steppedForms = state => state.steppedForm;

export const steppedFormSelector = createSelector(
	steppedForms,
	(state, props) => props.formId,
	(forms, formId) => forms[formId] || {}
);

export const stepSelector = createSelector(steppedFormSelector, form =>
	getNumericalVal(form, 'step')
);

export const completeSelector = createSelector(steppedFormSelector, form =>
	getNumericalVal(form, 'complete')
);
