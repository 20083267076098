// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-product-js": () => import("../src/templates/Product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-booking-js": () => import("../src/pages/booking.js" /* webpackChunkName: "component---src-pages-booking-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-intro-js": () => import("../src/pages/intro.js" /* webpackChunkName: "component---src-pages-intro-js" */),
  "component---src-pages-media-js": () => import("../src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-mim-js": () => import("../src/pages/mim.js" /* webpackChunkName: "component---src-pages-mim-js" */),
  "component---src-pages-music-js": () => import("../src/pages/music.js" /* webpackChunkName: "component---src-pages-music-js" */),
  "component---src-pages-shop-admin-index-js": () => import("../src/pages/shop/admin/index.js" /* webpackChunkName: "component---src-pages-shop-admin-index-js" */),
  "component---src-pages-shop-cart-index-js": () => import("../src/pages/shop/cart/index.js" /* webpackChunkName: "component---src-pages-shop-cart-index-js" */),
  "component---src-pages-shop-checkout-index-js": () => import("../src/pages/shop/checkout/index.js" /* webpackChunkName: "component---src-pages-shop-checkout-index-js" */),
  "component---src-pages-shop-checkout-thank-you-js": () => import("../src/pages/shop/checkout/thankYou.js" /* webpackChunkName: "component---src-pages-shop-checkout-thank-you-js" */),
  "component---src-pages-shop-index-js": () => import("../src/pages/shop/index.js" /* webpackChunkName: "component---src-pages-shop-index-js" */)
}

