// redux localStorage
export const LOCAL_STORAGE_KEY = 'eoa-com';

// netlify
export const NETLIFY_DOMAIN = process.env.GATSBY_NETLIFY_DOMAIN;

// stripe
export const STRIPE_PUBLISHABLE_KEY = process.env.GATSBY_STRIPE_PUBLISHABLE_KEY;
export const STRIPE_SECRET_KEY = process.env.GATSBY_STRIPE_SECRET_KEY;

// lambdas
export const PURCHASE_ENDPOINT = process.env.GATSBY_PURCHASE_ENDPOINT;
export const EDIT_ORDER_ENDPOINT = process.env.GATSBY_EDIT_ORDER_ENDPOINT;
export const MAILER_ENDPOINT = process.env.GATSBY_MAILER_ENDPOINT;
export const GRAPHQL_ENDPOINT = process.env.GATSBY_GRAPHQL_ENDPOINT;

// nodemailer
export const EMAIL_ADDRESS = process.env.GATSBY_EMAIL_ADDRESS;
