import { createSelector } from 'reselect';
import {
  compose as Rcompose,
  not,
  omit,
  pathEq,
  pathOr,
  prop,
  propOr,
  reduce,
} from 'ramda';
import { currencyToString } from '/src/utils/currency';

const CART_PATH = ['cart'];

// TODO: revamp shipping calc
export const isShippingSku = pathEq(['attributes', 'name'], 'shipping');

// cartSelector :: (State, Props) -> Array
export const cartSelector = pathOr({}, CART_PATH);

export const itemsSelector = createSelector(
  cartSelector,
  propOr([], 'items')
);

export const cartSizeSelector = createSelector(
  itemsSelector,
  items => items.length
);

export const subtotalSelector = createSelector(
  itemsSelector,
  items =>
    items.length
      ? items.reduce((acc, item) => acc + item.price * Number(item.quantity), 0)
      : 0
);

// TODO: implement these with live data
export const taxRateSelector = () => 0;

// TODO: use proper shipping data
export const shippingCostSelector = createSelector(
  itemsSelector,
  reduce((acc, sku) => acc + pathOr(0, ['shippingSku', 'price'], sku), 0)
);

export const taxSelector = createSelector(
  subtotalSelector,
  taxRateSelector,
  (subtotal, taxRate) => subtotal * taxRate
);

export const totalSelector = createSelector(
  subtotalSelector,
  taxSelector,
  shippingCostSelector,
  (subtotal, tax, shippingCost) => subtotal + tax + shippingCost
);

export const taxStringSelector = createSelector(
  taxSelector,
  currencyToString
);

export const shippingCostStringSelector = createSelector(
  shippingCostSelector,
  currencyToString
);

export const subtotalStringSelector = createSelector(
  subtotalSelector,
  currencyToString
);

export const totalStringSelector = createSelector(
  totalSelector,
  currencyToString
);

export const shippingSelector = createSelector(
  cartSelector,
  prop('shipping')
);

export const emailSelector = createSelector(
  shippingSelector,
  prop('email')
);

export const orderSelector = createSelector(
  itemsSelector,
  shippingSelector,
  (items, shipping) => ({
    currency: 'usd',
    email: shipping.email,
    items: items.filter(Rcompose(not, isShippingSku)).map(item => ({
      type: 'sku',
      parent: item.id,
      quantity: item.quantity,
    })),
    shipping: {
      name: `${shipping.firstName} ${shipping.lastName}`,
      address: {
        line1: shipping.line1,
        city: shipping.city,
        country: 'US',
        line2: shipping.line2,
        postal_code: shipping.zip,
        state: shipping.state,
      },
    },
  })
);

export const userFriendlyOrderSelector = createSelector(
  itemsSelector,
  shippingSelector,
  subtotalStringSelector,
  taxStringSelector,
  shippingCostStringSelector,
  totalStringSelector,
  (items, shipping, subtotal, tax, shippingCost, total) => ({
    email: shipping.email,
    items: items.map(item => ({
      name: item.product.name,
      skuName: item.attributes.name,
      price: currencyToString(item.price),
      quantity: item.quantity,
    })),
    shipping: omit(['email'], shipping),
    cost: { subtotal, tax, shippingCost, total },
  })
);

export const eventLinksSelector = createSelector(
  itemsSelector,
  items =>
    items.reduce(
      (acc, item) =>
        item.product.metadata.category === 'event'
          ? [...acc, item.product.metadata.fb_event]
          : acc,
      []
    )
);
