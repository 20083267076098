import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import * as types from './types';

const initialState = {
  sortBy: null,
  isSortAscending: true,
};

const sortByReducer = handleActions(
  {
    [types.SET_SORT_BY]: (state, { payload }) => ({
      ...state,
      [payload.id]: payload.value,
    }),
  },
  initialState.sortBy,
);

const isSortAscendingReducer = handleActions(
  {
    [types.TOGGLE_IS_SORT_ASCENDING]: (state, { payload: id }) => ({
      ...state,
      [id]: !state[id],
    }),
    [types.RESET_IS_SORT_ASCENDING]: (state, { payload: id }) => ({
      ...state,
      [id]: initialState.isSortAscending,
    }),
  },
  initialState.isSortAscending,
);

export default combineReducers({
  sortBy: sortByReducer,
  isSortAscending: isSortAscendingReducer,
});
