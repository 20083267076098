import { combineReducers } from 'redux';
// import { reducers as apiReducers } from 'redux-api-call';
// import { firebaseStateReducer as firebase } from 'react-redux-firebase';
// import auth from './auth';
// import connections from './connections';
import cart from './cart';
import sortableTable from './sortableTable';
import steppedForm from './steppedForm';

export default combineReducers({
  // ...apiReducers,
  // auth,
  cart,
  // connections,
  // firebase,
  steppedForm,
  sortableTable,
});
